export default {
	breakpoints: {
		sm: 0,
		md: '48em',
		lg: '80em'
	},
	colors: {
		black: '#000e1a',
		white: '#fff',
		blue: '#1a78fa',
		gray: '#ccc',
		darkGray: '#666',
		green: '#bbd9b5',
		coral: '#e9b6c1'
	}
}