import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
// import PropTypes from 'prop-types'
import React, { Component} from 'react'
import styled from 'styled-components'

import Burger from './burger';


const Logo = styled(GatsbyImage)`
	float: left;

	@media screen and (max-width:700px) {
		margin-left: 15px;
	}
`;


const StyledHeader = styled.header`
	max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.45rem 48px;

	> * {
		&:last-child {
			text-align: right;
		}
	}

	button {
		display: none;
	}

	.login-link:before {
		content: "/";
		display: inline-block;
		margin-left: 0.8em;
		margin-right: 1.6em;
	}

	@media screen and (max-width: 700px) {
		padding: 10px 15px 5px 0;

		button {
			display: inline-block;
		}

		.login-link:before {
			display: none;
		}
		.login-link {
			color: #666;
		}
	}
`;


const StyledMenuContainer = styled.nav`
	float: right;
	margin-top: 4px;

	a {
		font-family: lato;
		font-weight: 400;
		font-style: normal;
		font-size: 11px;
		letter-spacing: .2em;
		text-transform: uppercase;
		text-decoration: none;
		color: #000;

		margin: 0 .8em;
		padding: .8em 0;
		vertical-align: middle;

		&[aria-current], &:hover {
			color: #6699ff;
		}
	}

	@media screen and (max-width: 640px) {
		position: fixed;
		top: 0;
		bottom: 0;
		padding: 35px;
		width: 70%;
		width: calc(100% - 80px);
		z-index: 999;
		background-color: #fefefe;
		transform: translate3d(${props => props.mobileToggle ? '0' : '-100%'}, 0, 0);
		transition: transform 350ms cubic-bezier(.55, 0, .1, 1);

		a {
			display: block;
			font-size: 1.1rem;
		}
	}
`;


class Header extends Component {
	state = {
		mobileToggle: false
	}

	render () {
		const { menuLinks } = this.props;

		return (
            <StyledHeader className="clearfix">
				<Link to="/">
					<StaticQuery
						query={graphql`{
  imageLogo: file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 167, placeholder: NONE, layout: FIXED)
    }
  }
}
`}
						render={data => <Logo image={data.imageLogo.childImageSharp.gatsbyImageData} alt="" />}
					/>
				</Link>

				<StyledMenuContainer mobileToggle={this.state.mobileToggle}>
					{menuLinks.map((link, index) => {
						return (
							<Link to={link.link} key={index}>{link.name}</Link>
						)
					})}
					<a
						href={'https://skola.admentum.se'}
						target="_blank"
						className="login-link"
						rel="noopener noreferrer"
					>
						Logga in
					</a>
				</StyledMenuContainer>

				<div>
					{/* <StyledHamburger onClick={() => this.setState({mobileToggle: !this.state.mobileToggle})}>...</StyledHamburger> */}
					{/* https://mattvox.github.io/react-css-burger/ */}
					<Burger
						onClick={() => this.setState({mobileToggle: !this.state.mobileToggle})}
						active={this.state.mobileToggle}
						burger="collapse"
						color="#333"
						hoverOpacity={0.8}
						scale={.6}
						marginTop={'0'}
						className="hamburger"
					/>

				</div>
			</StyledHeader>
        );
	}

}

/*
Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}
*/

export default Header
