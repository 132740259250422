import React from 'react'
import styled from 'styled-components';

import facebookSvg from '../images/facebook.svg'
import twitterSvg from '../images/twitter.svg'

const StyledFooter = styled.footer`
	text-align: center;
	padding: 50px 20px;

	a {
		margin: 0 6px;
		opacity: .8;
		transition: all 100ms ease-in-out;

		&:hover {
			opacity: 1;
		}
	}

	img {
		width: 20px;
		height: 20px;
	}
`;

const Footer = () => (
	<StyledFooter>
		<a href="https://www.facebook.com/admentum" target="_blank" rel="noopener noreferrer">
			<img src={facebookSvg} alt="Facebook" />
		</a>
		<a href="https://twitter.com/skolplattformen" target="_blank" rel="noopener noreferrer">
			<img src={twitterSvg} alt="Twitter" />
		</a>
	</StyledFooter>
)

export default Footer
