import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Footer from './footer';
import Header from './header';
import theme from '../theme';
import './layout.css';

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					menuLinks {
						name
						link
					}
				}
			}
		}
		`}
		render={data => (
			<ThemeProvider theme={theme}>
				<div>
					<Header
						siteTitle={data.site.siteMetadata.title}
						menuLinks={data.site.siteMetadata.menuLinks}
					/>

					<div>
						{children}
						{/* <footer>
							© 2018, Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
						</footer> */}
					</div>

					<Footer />
				</div>
			</ThemeProvider>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
